const hrefAbout = '#about';
const hrefTech = '#tech';
const hrefWhatWeDo = '#what_we_do';

export const scrollToElement = (event, anchorArray) => {
  event.preventDefault();

  const {
    target: { href }
  } = event;

  switch (href) {
    case `${window.location.origin}/${hrefAbout}`:
      window.scrollTo({
        top: anchorArray[0],
        behavior: 'smooth'
      });
      break;

    case `${window.location.origin}/${hrefTech}`:
      window.scrollTo({
        top: anchorArray[1],
        behavior: 'smooth'
      });
      break;
    case `${window.location.origin}/${hrefWhatWeDo}`:
      window.scrollTo({
        top: anchorArray[2],
        behavior: 'smooth'
      });
      break;
  }
};
