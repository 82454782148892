import React, { useEffect, useState } from 'react';

const withAnchorLink = WrappedComponent => props => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [aboutUsTop, setAboutUsTop] = useState(0);
  const [techTop, setTechTop] = useState(0);
  const [whatWeDoTop, setWhatWeDoTop] = useState(0);

  const setContainersParams = containers => {
    const headerHeight = containers[0]?.getClientRects()[0].height;
    const subtrahendValue = window.pageYOffset - headerHeight;

    setHeaderHeight(headerHeight);
    setAboutUsTop(containers[1]?.getBoundingClientRect().top + subtrahendValue);
    setTechTop(containers[2]?.getBoundingClientRect().top + subtrahendValue);
    setWhatWeDoTop(containers[3]?.getBoundingClientRect().top + subtrahendValue);
  };

  const setAnchorParams = containers => {
    if (containers[0].getClientRects()[0].height !== headerHeight) {
      setContainersParams(containers);
    }
  };

  useEffect(() => {
    const header = document.querySelector('.header-container');
    const aboutContainer = document.querySelector('.about-us-container');
    const techContainer = document.querySelector('.tech-container');
    const whatWeDoContainer = document.querySelector('.what-we-do-container');

    const containers = [
      header,
      aboutContainer,
      techContainer,
      whatWeDoContainer
    ];
    setContainersParams(containers);

    window.addEventListener('resize', () => {
      setAnchorParams(containers);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setAnchorParams(containers);
      });
    };
  }, []);

  const anchorArray = [aboutUsTop, techTop, whatWeDoTop];

  return <WrappedComponent anchorArray={anchorArray} {...props} />;
};

export default withAnchorLink;
