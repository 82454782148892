import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({ text, link }) => (
  <a className='btn btn-primary' href={link}>
    {text}
  </a>
);

Button.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string
};

export default Button;
