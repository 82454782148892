import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import withAnchorLink from '../Common/AnchorLink/withAnchorLink';
import { scrollToElement } from '../Common/AnchorLink/scrollToElement';
import './header.scss';

const HeaderLinks = ({
  links,
  className,
  mobileOpen,
  isMobileOpen,
  anchorArray
}) => (
  <ul className={className}>
    {links.map(({ title, path, tabIndex }) => {
      if (title === 'Registration') {
        return (
          <li className='header-links-list-item' key={title}>
            <a
              className='footer-menu-link'
              href={path}
              tabIndex={tabIndex}
              onClick={isMobileOpen ? mobileOpen : undefined}
            >
              {title}
            </a>
          </li>
        );
      } else {
        return (
          <li className='header-links-list-item' key={title}>
            <Link
              className='header-links'
              onClick={
                isMobileOpen
                  ? e => {
                      scrollToElement(e, anchorArray);
                      mobileOpen();
                    }
                  : e => scrollToElement(e, anchorArray)
              }
              to={path}
              tabIndex={tabIndex}
            >
              {title}
            </Link>
          </li>
        );
      }
    })}
  </ul>
);

HeaderLinks.propTypes = {
  links: PropTypes.array,
  className: PropTypes.string,
  mobileOpen: PropTypes.func,
  isMobileOpen: PropTypes.bool,
  anchorArray: PropTypes.array
};

export default withAnchorLink(HeaderLinks);
