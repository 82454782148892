import React from 'react';
import PropTypes from 'prop-types';

import HeaderLinks from './HeaderLinks';
import CloseIcon from './CloseIcon';
import SignUp from './SignUp';

const HeaderMenu = ({
  primaryLinks,
  isMobileOpen,
  mobileOpen,
  secondary_links
}) => {
  return (
    <div className={`header-menu ${isMobileOpen && 'open'}`}>
      <CloseIcon isMobileOpen={isMobileOpen} mobileOpen={mobileOpen} />
      <SignUp link='//track.check-emails.com/click.php?key=check-emails.com' />
      <HeaderLinks
        isMobileOpen={isMobileOpen}
        mobileOpen={mobileOpen}
        links={primaryLinks}
        className='primary-links'
      />
      <HeaderLinks
        isMobileOpen={isMobileOpen}
        mobileOpen={mobileOpen}
        links={secondary_links}
        className='secondary-links'
      />
    </div>
  );
};

HeaderMenu.propTypes = {
  primaryLinks: PropTypes.array,
  isMobileOpen: PropTypes.bool,
  mobileOpen: PropTypes.func,
  links: PropTypes.array,
  secondary_links: PropTypes.array
};

export default HeaderMenu;
