import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import withAnchorLink from '../Common/AnchorLink/withAnchorLink';
import { scrollToElement } from '../Common/AnchorLink/scrollToElement';

const FooterMenu = ({ links, anchorArray }) => {
  return (
    <div className='footer-menu'>
      <ul>
        {links.map(({ title, path }) => {
          if (title === 'Registration' || title === 'Terms') {
            return (
              <li className='footer-menu-item' key={title}>
                <a className='footer-menu-link' href={path}>
                  {title}
                </a>
              </li>
            );
          } else {
            return (
              <li className='footer-menu-item' key={title}>
                <Link
                  className='footer-menu-link'
                  onClick={e => scrollToElement(e, anchorArray)}
                  to={path}
                >
                  {title}
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

FooterMenu.propTypes = {
  links: PropTypes.array,
  title: PropTypes.string,
  link: PropTypes.string,
  anchorArray: PropTypes.array
};

export default withAnchorLink(FooterMenu);
