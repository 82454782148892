import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import LinkImage from '../Common/LinkImage';
import { FOOTER_TOKENS as FOOTER_LINKS } from '../../constants/Languages';
import FooterMenu from './FooterMenu';

import './Footer.scss';

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "check_emails_com_svg.png" }) {
            childImageSharp {
              fluid(maxWidth: 162, maxHeight: 54) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          pages: allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/content/" } }
          ) {
            totalCount
            edges {
              node {
                frontmatter {
                  title
                  path
                  tags
                }
              }
            }
          }
        }
      `}
      render={data => {
        const {
          pages: { edges }
        } = data;
        let secondHeaderLinks = [];
        edges.forEach(
          ({
            node: {
              frontmatter: { title, path, tags }
            }
          }) => {
            if ((tags || []).includes('secondary_header')) {
              secondHeaderLinks.push({ title, path });
            }
          }
        );
        return (
          <footer className='footer-container'>
            <div className='footer'>
              <LinkImage
                src={data.logo.childImageSharp.fluid.src}
                href='/'
                alt='Chech eMails'
                imgClassName='footer-logo'
                linkClassName='footer-logo-container'
              />
              <FooterMenu links={FOOTER_LINKS} />
              <span className='copyrights'>OSA MANAGEMENT LLP, Suite 4005 43 Bedford Street, London, England, WC2E 9HA</span>
              <span className='copyrights'>Copyrights &copy; 2019-2024</span>
            </div>
          </footer>
        );
      }}
    />
  );
};

export default Footer;
